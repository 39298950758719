import { Icon, PopUpDialog } from '@maverick/ui';
import { FC, useState, useEffect } from 'react';
import { useCookies } from '@maverick/hooks';
import { formatTime, GaEvent, getDateWithOffset } from '@maverick/utils';
import { differenceInMilliseconds } from 'date-fns';
import { LocationReduxProps } from '.';
import { setCookie } from '@maverick/ui';
import { Cookies } from '@maverick/entity';
import { LocationManager } from '@maverick/cms';
import { LocationIcon } from '@maverick/icons/dist/LocationIcon';
import * as Styled from './Location.styled';
import { LocationPopUpContent } from './LocationPopUpContent';
import { useRouter } from 'next/router';

interface LocationProps extends LocationReduxProps {}

export const Location: FC<LocationProps> = ({ seatingData, restaurant, setRestaurant }) => {
	const [hasSeating, setHasSeating] = useState<boolean>(false);
	const [yextUrl, setYextUrl] = useState<string | null>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [favoriteStore] = useCookies(Cookies.FavoriteStore);
	const [currentPage, setCurrentPage] = useState<string>('');
	const router = useRouter();

	useEffect(() => {
		if (router) {
			setCurrentPage(router.asPath);
		}
	}, [router]);

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		initFavoriteStore();
	}, [favoriteStore]);

	useEffect(() => {
		handleLocation();
	}, [restaurant]);

	useEffect(() => {
		handleSeating();
	}, [seatingData, restaurant]);

	const initFavoriteStore = async () => {
		if (!favoriteStore) {
			setRestaurant(null);
			GaEvent.Global.Set('store_id', 'not selected');
			return;
		}

		const restaurant = await LocationManager.GetLocationByRef(favoriteStore);

		if (!restaurant) {
			setRestaurant(null);
			GaEvent.Global.Set('store_id', 'not selected');
			return;
		}

		setRestaurant(restaurant);
		GaEvent.Global.Set('store_id', Number(favoriteStore));
	};

	const handleLocation = async () => {
		if (!favoriteStore || !restaurant) {
			return;
		}

		await handleYextUrl();
	};

	const handleYextUrl = async () => {
		const result = await LocationManager.GetLocationInfo(favoriteStore!);
		if (!result) return;

		if (result.yextUrl) {
			setYextUrl(result.yextUrl);
		}

		setCookie(Cookies.WhitelabelUrl, restaurant!.url);
	};

	const handleSeating = async () => {
		if (seatingData?.IsWaitlist) {
			setHasSeating(false);
			return;
		}
		if (!seatingData?.PartyKey || !seatingData?.Datetime) {
			setHasSeating(false);
			return;
		}

		if (seatingData.PartyKey && restaurant) {
			const restaurantDate = getDateWithOffset(restaurant.utcoffset);
			const isInTheFuture = differenceInMilliseconds(seatingData.Datetime, restaurantDate) > 0;
			if (!isInTheFuture) {
				setHasSeating(false);
			} else {
				setHasSeating(true);
			}
		}
	};

	const renderSeatingDetails = () => {
		const [hours, minutes] = seatingData?.TimeSlot?.split(':') || ['', ''];
		if (hours === '' || minutes === '' || !hasSeating || !seatingData) {
			setHasSeating(false);
			return '';
		}
		return `Party of ${seatingData.Guests}, ${formatTime(hours, minutes)}`;
	};

	const locationContent = (
		<LocationPopUpContent
			yext={yextUrl}
			seatingDetails={hasSeating && seatingData ? renderSeatingDetails() : null}
			handleModal={handleCloseModal}
		/>
	);

	return (
		<Styled.Container route={currentPage}>
			{!restaurant ? (
				<Styled.LocationContainer
					href='https://locations.outback.com/search'
					data-testid='no-location'
					onClick={() => {
						GaEvent.TopNavigation('Find location');
						GaEvent.OutboundLinkClick('https://locations.outback.com/search');
					}}
					aria-label='edit location'
				>
					<Styled.LocationIcon>
						<Icon icon={LocationIcon} customSize />
					</Styled.LocationIcon>
					<Styled.Name data-testid='find-location' hasLocation={false}>
						Find location
					</Styled.Name>
				</Styled.LocationContainer>
			) : (
				<PopUpDialog isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} content={locationContent}>
					<Styled.LocationPopUpButton
						onClick={() => setIsModalOpen(!isModalOpen)}
						aria-expanded={isModalOpen}
						aria-haspopup='dialog'
					>
						<Styled.LocationIcon>
							<Icon icon={LocationIcon} customSize />
						</Styled.LocationIcon>
						<Styled.Content data-testid='content'>
							<Styled.Name data-testid='location-name' hasLocation={true}>
								{restaurant.name}
							</Styled.Name>
						</Styled.Content>
					</Styled.LocationPopUpButton>
				</PopUpDialog>
			)}
		</Styled.Container>
	);
};
